import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "./autoswiper.scss";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Autoplay } from "swiper";
import {useState} from "react";
import nft from "../assets/nft.jpg";
import nft1 from "../assets/nft1.jpg";
import nft2 from "../assets/nft2.jpg";

SwiperCore.use([FreeMode, Autoplay]);

const des = [`"如花在野·奥妙若兰NO.2"`, `"如花在野·奥妙若兰NO.3"`, `"如花在野·奥妙若兰NO.4"`];

export default function AutoSwiper(){
    const [currentIndex,setIndex] = useState(1);


    const handleSlideChange = (e) => {
        let index = e.realIndex;
        setIndex(index);

    }

    return(
        <div id="swiper-container">
            <div className="nft-id">
                # 0{currentIndex + 2}
            </div>
            <Swiper slidesPerView={'auto'} spaceBetween={30} centeredSlides={true} autoplay={{delay: 1000,disableOnInteraction: false}} loop={true}
                    className="home-swiper"
                    onSlideChange={handleSlideChange}>
                <SwiperSlide><img src={nft} /></SwiperSlide>
                <SwiperSlide><img src={nft1} /></SwiperSlide>
                <SwiperSlide><img src={nft2} /></SwiperSlide>
            </Swiper>
            <div>
                {des[currentIndex]}
            </div>
        </div>
    )

}