import {useNavigate} from "react-router";
import light from "../assets/light-congrats.png";
import leftPlant from "../assets/left-plant.png";
import rightPlant from "../assets/right-plant.png";
import platform from "../assets/platform.png";
import "./congrats.scss";
import frame from "../assets/frame-active.png";
import Loading from "../components/Loading";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import platformLight from "../assets/platform-light.png";



export default function Congrats(){

    let navigate = useNavigate();
    let [params] = useSearchParams();
    const confirm = () => {
        navigate('/list');
    }

    let url = params.get("url");
    let name = params.get("name");
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        },1000)
    },[])
    return(
        <div id="congrats-container">
            <div id="light-container">
                <img src={light} />
            </div>
            <div className="home-title">
                <div className="plant-container">
                    <img src={leftPlant} />
                </div>
                <div>
                    恭喜您获得
                </div>
                <div className="plant-container">
                    <img src={rightPlant} />
                </div>

            </div>
            <div className="nft-container">
                <img src={url} style={{position:'absolute',width: "160px", height: "160px"}}/>
                <img src={frame}/>
            </div>
            <div>
                {name}
            </div>

            {/*<div id="platform">*/}
            {/*    <img src={platform} style={{width:'100%',zIndex:2, position:"relative"}}/>*/}
            {/*    <img src={platformLight} style={{position:'absolute',left:0,top:'10px',zIndex:1}}/>*/}
            {/*</div>*/}

            <div id='retrieve-button' onClick={confirm}>
                确认
            </div>

            <Loading text={'努力铸造中...'} isShowing={!loaded}/>
        </div>
    )
}