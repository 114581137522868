import AutoSwiper from "../components/AutoSwiper";
import "./home.scss";
import platform from "../assets/platform.png"
import leftPlant from "../assets/left-plant.png";
import rightPlant from "../assets/right-plant.png";
import light from "../assets/light.png"
import DutyFree from "../components/DutyFree";
import {useNavigate} from "react-router";
import {useContext} from "react";
import {AuthContext} from "../App";
import platformLight from "../assets/platform-light.png";


export function Home(){
    let navigate = useNavigate();
    const { state } = useContext(AuthContext);
    const claim = () => {
        if(state.isLogin){
            navigate('/claim')
        }else{
            navigate('/login');
        }
    }
    return(
        <div id="home-container">
            <div id="light-container">
                <img src={light} />
            </div>
            <div className="home-title">
                <div className="plant-container">
                    <img src={leftPlant} />
                </div>
                <div>
                    如花在野数字藏品
                </div>
                <div className="plant-container">
                    <img src={rightPlant} />
                </div>

            </div>
            <AutoSwiper />
            {/*<div id="platform">*/}
            {/*    <img src={platform} style={{width:'100%',zIndex:2,position:'relative'}}/>*/}
            {/*    <img src={platformLight} style={{position:'absolute',left:0,top:0,zIndex:1}}/>*/}
            {/*</div>*/}


            <div id='retrieve-button' onClick={claim}>
                领取我的数字藏品
            </div>

            <div className='duty-container'>
                <DutyFree />
            </div>



        </div>
    )
}