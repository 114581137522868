import BackButton from "../components/BackButton";
import "./claim.scss"
import {useContext, useState} from "react";
import question from "../assets/question.png";
import {AuthContext, serverAddress} from "../App";
import {responseCode} from "../config";
import {useNavigate} from "react-router";
import {bottomToast} from "../utils/toast";
export default function Claim(){

    const [code, setCode] = useState();
    const {state} = useContext(AuthContext);
    let navigate = useNavigate();

    const handleClaim = () => {
        if(!state.isLogin){
            navigate("/login");
        }else{
            try {
                fetch(
                    serverAddress + "/nfts/redemption",
                    {
                        method: "POST",
                        headers: {"accessToken": state.accessToken},
                        body: JSON.stringify({"collectionId": 6, "code": code })
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if(result.returnCode === responseCode.success ){
                            const nft = result.data.nfts;
                            let {name,tokenId,thumbnailURL} = nft[0];
                            navigate("/congrats?name=" + name + "&tokenId=" + tokenId + "&url=" + thumbnailURL);
                        }else if(result.returnCode === responseCode.redemptionCodeInvalid){
                            bottomToast("请输入正确的领取码");

                        }else if(result.returnCode === responseCode.redemptionCodeAlreadyUsed){
                            bottomToast("该兑换码已使用");
                        }
                    })
                } );
            } catch (error) {
                console.log("error", error);
            }
        }

    }
    return(
        <div id="claim-container">

            <div style={{padding: '18px 0 0 18px'}}>
                <BackButton />
            </div>
            <div id="claim-content">
                <div id='question-container'>
                    <img src={question}/>
                </div>
                <div id="code-input">
                    <input size="1" maxLength="8" onChange={(e) => {setCode(e.target.value)}} onClick={(e) => {e.target.scrollIntoView({inline: "start"})}} placeholder="输入领取码"/>
                </div>

                <div id='claim-button' onClick={handleClaim}>
                    领取我的数字藏品
                </div>
            </div>
        </div>
    )
}