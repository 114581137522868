import "./nftlist.scss"
import DutyFree from "../components/DutyFree";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import add from "../assets/add.png"
import {AuthContext, serverAddress} from "../App";
import {responseCode} from "../config";
import { Link } from "react-router-dom";

export default function NFTlist(){

    const [list, setList] = useState([]);
    const {state} = useContext(AuthContext);
    let navigate = useNavigate();
    const goClaim = () => {
        navigate('/claim')
    }

    useEffect(() => {
        try {
            fetch(
                serverAddress + "/myNFTs?collectionId=6&pageSize=100&pageNum=1",
                {
                    method: "GET",
                    headers: {"accessToken": state.accessToken},
                }
            ).then((r) => {
                r.json().then((result) => {
                    if(result.returnCode === responseCode.success ){
                        const nfts = result.data.nfts;
                        console.log(nfts);
                        setList(nfts);
                    }
                })
            } );
        } catch (error) {
            console.log("error", error);
        }
    },[state.accessToken])
    return(
        <div id="list-container">
            <div id="content-container">
                {
                     list.map((nft) => {
                        return (
                            <div className='card-wrapper'>
                                <Link to={'/detail/' + nft.tokenId}>
                                    <div className='card-container' key={nft.tokenId} id={nft.tokenId}>
                                        <img src={nft.thumbnailURL} style={{width: "130px", height: "130px"}}/>
                                    </div>
                                </Link>

                                <div className='nft-name'>
                                    {nft.name}
                                </div>
                            </div>
                        )
                     })
                }
                <div className="card-wrapper">
                    <div className='card-container' onClick={goClaim}>
                        <div className='add-container'>
                            <img src={add} />
                        </div>
                    </div>
                </div>


            </div>

            <div className='duty-container'>
                <DutyFree />
            </div>

        </div>
    )
}